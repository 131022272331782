import axiosInstance from "../lib/axiosInstance.js";

class UserService {
    #url = process.env.VUE_APP_ABCPANEL_URL + "/ab/rest/cpanel/users";

    getUsers(page, search) {
        return axiosInstance.get(this.#url, {params:{
            search: search,
            page: page
        }})
    }

    getUser(id) {
        return axiosInstance.get(this.#url + "/" +id)
    }

    getUserSettings(userId) {
        return axiosInstance.get(this.#url + "/settings/" + userId,);
    }

    saveUserSettings(data) {
        return axiosInstance.post(this.#url + "/settings", {
            userId: data.userId,
            maxStake: data.maxStake,
            vip: data.vip
        });
    }

    getUserActiveSubscriptions(userId, page) {
        return axiosInstance.get(this.#url + "/" +userId + "/subscriptions", {
            params:{
                page: page
        }})
    }
    getUserSubscribers(userId, page) {
        return axiosInstance.get(this.#url + "/" +userId + "/subscribers", {
            params:{
                page: page
            }})
    }
    getUserSubscriptionsConfig(userId) {
        return axiosInstance.get(this.#url + "/" +userId + "/subscriptions/config")
    }
}

export default new UserService()