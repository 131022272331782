<template>
    <div class="users-list">
        <div class="row">
            <div class="col-12">
                <h3>Логи по кошелькам</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-row align-items-center">
                    <div class="col-sm-3 my-1">
                        <label class="sr-only" for="inlineFormInputGroupUsername">Имя пользователя</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">Поиск</div>
                            </div>
                            <input type="text" v-model="search" class="form-control"
                                   id="inlineFormInputGroupUsername" placeholder="Имя пользователя">
                        </div>
<!--                        <div>-->
<!--                          <UsersAutoCompleete></UsersAutoCompleete>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table id="users-list-table" class="table users-list-table">
                    <tr>
                        <th>ID</th>
                        <th class="text-left">Имя пользователя</th>
                        <th>Баланс, $</th>
                        <th>Роль</th>
                    </tr>
                    <tr v-for="user in users" v-bind:key="user.username">
                        <td width="150px">
                            {{ user.id }}
                        </td>
                        <td class="text-left">
                            <router-link :to="{ name: 'User', params: { id: user.id }}">{{ user.username }}</router-link>
                        </td>
                        <td>
                            <span v-if="null != user.purse">
                                {{ user.purse.balance }}
                            </span>
                        </td>

                        <td>{{ user.role }}</td>
                    </tr>
                </table>
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        v-on:change="selectPage"
                        aria-controls="users-list"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from "../services/UserService";
    // import UsersAutoCompleete from "../components/UsersAutoCompleete";

    export default {
        name: "UsersList",
      // components: {UsersAutoCompleete},
      data: function(){
            return {
                currentPage: 1,
                totalRows: 0,
                perPage:1,
                users: [],
                search: ""
            }
        },
        methods: {
            selectPage(page) {
                this.currentPage=page;
                this.loadUsers()
            },
            loadUsers(){
                var self = this;
                UserService.getUsers(self.currentPage-1, self.search)
                    .then((response) => {
                        self.users = response.data.content;

                        self.currentPage = response.data.number+1;
                        self.totalRows = response.data.totalElements;
                        self.perPage = response.data.size;
                    })
            }
        },

        watch: {
            search: function (val) {
                if (val == '' || val.length >=1) {
                    this.loadUsers();
                }
            }
        },
        mounted() {
            this.loadUsers()
        }
    }
</script>

